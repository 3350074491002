<template>
  <div class="account__wrapper">
    <div v-if="user" class="account__form">
      <div class="account__form-columns">
        <div class="account__form-column">
          <div class="account__title">My Account</div>
          <div class="account__image">
            <img
              v-if="!imageUrl"
              :src="user.avatar"
              class="account__avatar"
              :alt="user.name"
            />
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="account__new-avatar"
              alt="new image"
            />
            <span class="account__upload" v-on:click="$refs.fileInput.click()">
              <IconCamera />
            </span>
            <input
              type="file"
              accept="image/*"
              ref="fileInput"
              v-on:change="onChange"
              hidden
            />
          </div>
        </div>
        <div class="account__form-column">
          <div class="account__field">
            <label for="name">Name</label>
            <input
              type="text"
              id="name"
              v-model="user.name"
              class="account__input"
            />
            <div class="account__field-validation">
              <span v-if="!validationObj.name.validation"
                >Please fill in Name field</span
              >
            </div>
          </div>
          <div class="account__field">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="user.email"
              v-on:change="changeEmail"
              class="account__input"
            />
            <div class="account__field-validation">
              <span v-if="!validationObj.email.validation"
                >Please fill in Email field</span
              >
            </div>
          </div>
          <div class="account__field">
            <label for="pass">New password</label>
            <input
              v-model="user.password"
              type="password"
              autocomplete="new-password"
              id="pass"
              class="account__input"
            />
            <div class="account__field-validation">
              <span v-if="!validationObj.password.validation"
                >Please fill in New password field</span
              >
            </div>
          </div>
          <div class="account__field">
            <label for="confirm">Re-enter your new password</label>
            <input
              type="password"
              v-model="user.confirm"
              id="confirm"
              class="account__input"
            />
          </div>
        </div>
      </div>
      <div class="account__form-action">
        <BaseButton class-name="big info " text="Submit" @click="submitForm" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import BaseButton from "@/modules/components/base/BaseButton.vue";
import IconCamera from "@/modules/components/icons/IconCamera.vue";

export default {
  name: "UserAccount",
  components: { BaseButton, IconCamera },
  setup() {
    const store = useStore();
    const image = ref(false);
    const imageUrl = ref(false);
    const isSubmitting = ref(false);
    const user = computed(() => store.getters["auth/userInfo"]);
    let emailChanged = ref(false);
    const validationObj = ref({
      name: { type: "text", validation: true },
      email: { type: "email", validation: true },
      password: { type: "password", validation: true },
      confirm: { type: "password-confirmation", validation: true },
    });

    const submitForm = () => {
      if (!formValidation(validationObj, user.value)) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const formData = new FormData();
      formData.append("name", user.value.name);
      if (emailChanged.value == true) {
        formData.append("email", user.value.email);
      }
      if (
        user.value.password &&
        user.value.password.length &&
        user.value.password == user.value.confirm
      ) {
        formData.append("password", user.value.password);
      }

      if (image.value !== false) {
        formData.append("avatar", image.value);
      }

      store
        .dispatch("auth/updateUserAccount", {
          id: user.value.id,
          formData: formData,
        })
        .then(() => {
          store.dispatch("notifications/addNotifications", [
            {
              title: "My Account",
              text: "Information saved!",
            },
          ]);
        });
      emailChanged.value = false;
    };

    const formValidation = (obj, computedValue) => {
      let list = Object.entries(obj.value);
      let validation = true;
      list.forEach((el) => {
        const item = obj.value[el[0]];
        item.validation = computedValue[el[0]] && !!computedValue[el[0]].length;
        if (!computedValue[el[0]] || !computedValue[el[0]].length) {
          validation = false;
        }
      });
      return validation;
    };

    const changeEmail = () => {
      emailChanged.value = true;
    };

    const onChange = (e) => {
      image.value = e.target.files[0];
      imageUrl.value = URL.createObjectURL(image.value);
    };

    return {
      user,
      isSubmitting,
      submitForm,
      onChange,
      imageUrl,
      image,
      changeEmail,
      validationObj,
    };
  },
};
</script>

<style scoped lang="scss">
.account {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form {
    width: 735px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 8px 0px rgba(0, 0, 0, 0.25);
  }

  &__avatar {
    border: 5px solid var(--col-bg-attention);
    border-radius: 50%;
  }

  &__new-avatar {
    position: absolute;
    top: 98px;
  }

  &__image {
    position: relative;
    padding-top: 75px;
    width: 123px;
    margin: 0 auto;
  }

  &__upload {
    cursor: pointer;
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    top: 110px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 55px;
    text-align: center;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form-columns {
    display: flex;
    justify-content: space-between;
  }

  &__form-column {
    width: 420px;
    padding: 41px 50px 0 0;

    &:first-child {
      position: relative;
      width: 265px;
      padding: 35px 0 0;

      &:after {
        content: "";
        position: absolute;
        height: 320px;
        width: 1px;
        right: -2px;
        top: 53px;
        background: #17a2b8;
      }
    }
  }

  &__title {
    font-size: 33px;
    font-weight: bold;
    text-align: center;
  }

  &__field {
    position: relative;
    margin-bottom: 22px;

    &-validation {
      position: relative;

      span {
        position: absolute;
        font-size: 12px;
        color: #dc3545;
        bottom: -13px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      bottom: 2px;
      background: #b0b0b0;
      height: 1px;
      position: absolute;
    }

    label {
      color: #e0e0e0;
      font-size: 16px;
      margin-bottom: 10px;
      display: block;
    }
  }

  &__input {
    margin: 0;
    padding: 0;
    border: 0;
    color: #6c757d;
    border-radius: 0;
    font-size: 36px;
    line-height: 36px;
    height: 33px;
    -webkit-box-shadow: 0 0 0 30px white inset !important;

    &:-webkit-autofill::first-line {
      font-size: 36px;
    }
  }

  &__form-action {
    width: 280px;
    margin: 0 auto;
    padding: 27px 0 0;
    margin-bottom: 30px;

    .btn {
      padding: 6px 0;
    }
  }
}
</style>
